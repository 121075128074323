<template>
  <v-app>
    <v-main>
      <navbar></navbar>
      <div class="main-container">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>
<script>
import Navbar from "@/components/navigators/Navbar.vue";
import "@/assets/css/core.scss";
export default {
  components: { Navbar },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.v-application {
  background-position: center;
  background-color: #01132F;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url('../assets/bg.png');
  color: #000;
}
</style>
